import { tipusPredef as tipusImatgePredef } from '../../../../imatgeConstants';
import { dataOptimizer } from '../../../../jitDataOptimizr';
const fields = [
    'agrupacions',
    'avantitol',
    'canals[0].id',
    'codi_etic[0].desc',
    'codi_etic[0].id',
    'codis_etics[0].desc',
    'codis_etics[0].id',
    'data_emissio',
    'durada',
    'entradeta',
    'entradeta_promo',
    'formats[0].id',
    'id',
    'logos_canals[0].id',
    'programa',
    'tipologia',
    'titol',
    'nombonic'
];

const optimizeDataSpecs = [
    {
        key: 'potserBusquesTv',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: fields,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.web_postergran]
                }
            ]
        }
    },
    {
        key: 'potserBusquesRadio',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: fields,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.web_postergran]
                }
            ]
        }
    },
    {
        key: 'cercaProgramesTv',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: fields,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.web_postergran]
                }
            ]
        }
    },
    {
        key: 'cercaProgramesRadio',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: fields,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.web_postergran]
                }
            ]
        }
    }
];

export const optimizeData = (data, propValue) => {
    return dataOptimizer(optimizeDataSpecs, data, propValue);
};
