const TYPE = {
    ERROR: 'Error: ',
    WARNING: 'Atenció: ',
    INFO: 'Info: '
};

const printLog = (type, comment) => {
    console.log(type + comment);
};

/**
 * Registra l'error al nostre Logging Service
 * @param {*} error l'objecte Error de JavaScript capturat al `catch`
 * @param {*} moduleName (opcional) el nom del mòdul
 * @param {*} details (opcional) detalls addicionals, seran convertits a `string`
 */
const printError = (error, moduleName, details) => {
    let message = '[ERROR] ' + (error ? (error.message ? error.message : error) : 'Unknown');
    if (moduleName) {
        message += '\n  Mòdul: ' + moduleName;
    }
    if (error.code) {
        message += '\n  Codi: ' + error.code;
    }
    if (error.cause) {
        message += '\n  Causa: ' + error.cause;
    }
    if (details) {
        message += '\n  Detalls: ' + details.toString();
    }
    console.log(message);
}

export { printError, printLog, TYPE };
