import { useRouter } from 'next/router';
import { DidomiSDK } from '@didomi/react';
import didomiConfig from './didomiConfig';
import { useState, useEffect } from 'react';
import excludes from './excludes';
import { getRequiredServices } from '@portal-internet/core';

export default function AvisCookies(props) {
    const { dataFetchProps, didomiService } = props;

    const requiredServices = getRequiredServices(dataFetchProps, didomiService);
   

    let didomiObject = {};
    const { asPath } = useRouter();
    const [adobeConsent, setAdobeConsent] = useState('no');
    const mostraCMP = excludes.findIndex((exclude) => asPath.includes(exclude)) === -1;

    const onDidomiReady = (didomi) => {
        didomiObject = didomi;
        setAdobeConsent(didomiObject.getUserConsentStatusForVendor(565));
    };

    const consentHasChanged = (cwtToken) => {
        setAdobeConsent(didomiObject.getUserConsentStatusForVendor(565));
    };

    const setGlobalEvar = (index, eVar) => {
        window.globalEvars = window.globalEvars || {};
        window.globalEvars[index] = eVar;
    };

    useEffect(() => {
        if (window && window.digitalData && window.digitalData.page) {
            window.digitalData.page.consent = adobeConsent === true ? 'sí' : 'no';
            setGlobalEvar('eVar89', window.digitalData.page.consent);
        }
    }, [adobeConsent]);

    if (!requiredServices?.areAllServicesAvailable) {
        return <></>;
    }

    return mostraCMP ? (
        <DidomiSDK
            iabVersion={2}
            config={didomiConfig}
            noticeId='cDRKMynp'
            gdprAppliesGlobally={true}
            onReady={onDidomiReady}
            onConsentChanged={consentHasChanged}
        />
    ) : (
        <></>
    );
}

AvisCookies.defaultProps = {
    didomiService: ['didomi']
};
