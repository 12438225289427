import { createContext, useState } from 'react';
export const ModalContext = createContext();

const defaultOptions = {
    showControls: true,
    mode: 'full-screen'
};

const ModalProvider = (props) => {
    const { children } = props;

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [optionsModal, setOptionsModal] = useState(defaultOptions);

    const setModal = (content, options = defaultOptions) => {
        options = { ...defaultOptions, ...options };
        setOpenModal(true);
        setOptionsModal(options);
        setModalContent(content);

        if (typeof document !== 'undefined' && options.mode === 'full-screen') document.body.classList.add('is-modal-fullscreen');
    };

    const removeModal = () => {
        if (typeof document !== 'undefined' && optionsModal.mode === 'full-screen')
            document.body.classList.remove('is-modal-fullscreen');

        setOpenModal(false);
        setModalContent(null);
    };

    const centerDivOnModal = (idDiv) => {
        const containerScroll = document.getElementById('container-modal');
        const centeredDiv = document.getElementById(idDiv);

        const containerScrollHeight = containerScroll.clientHeight;
        const centeredDivHeight = centeredDiv.clientHeight;

        containerScroll.scrollTop = centeredDiv.offsetTop + centeredDivHeight / 2 - containerScrollHeight / 2;
    };

    return (
        <ModalContext.Provider
            value={{ openModal, modalContent, optionsModal, setModal, removeModal, centerDivOnModal }}
            children={children}
        />
    );
};

export default ModalProvider;
