import { tipusPredef as tipusImatgePredef } from '../../../../imatgeConstants';

export const directes_itemsPickPaths = [
    'ara_fem.capitols[0].desc',
    'ara_fem.codi_canal',
    'ara_fem.codi_etic',
    'ara_fem.destacat_imatge',
    'ara_fem.drets_cat',
    'ara_fem.end_time',
    'ara_fem.sinopsi',
    'ara_fem.start_time',
    'ara_fem.titol_capitol',
    'ara_fem.titol_programa',
    'ara_fem.tipus',
    'name',
    'entradeta',
    'codis_etics',
    'titol',
    'capitols[0].desc',
    'drets_cat',
    'data_emissio',
    'canals[0].id'
];

const tira_items_itemsPickPaths = [
    'agrupacions',
    'avantitol',
    'canals',
    'canals[0].id',
    'codi_etic',
    'codi_etic[0].desc',
    'codi_etic[0].id',
    'codis_etics[0].desc',
    'codis_etics[0].id',
    'data_emissio',
    'domini',
    'durada',
    'entradeta_promo',
    'entradeta',
    'etiquetes',
    'formats[0].id',
    'id',
    'isDirecte',
    'logos_canals[0].id',
    'nombonic',
    'permatitle',
    'programa',
    'programes.programa[0].id',
    'programes.programa[0].nom_bonic',
    'programes.programa[0].nom',
    'programes.programa[0].tipologia',
    'programes[0].id',
    'programes[0].nom_bonic',
    'programes[0].nom',
    'programes[0].tipologia',
    'programes_radio',
    'slug',
    'tipologia',
    'tipus',
    'titol_promo',
    'titol',
    'url'
];

const infocontingut_itemsPickPaths = [
    'contingut_id',
    'tipus_contingut',
    'tipus_test',
    'llista_id',
    'usuari_id',
    'ordre',
    'info_contingut.agrupacions',
    'info_contingut.avantitol',
    'info_contingut.canals',
    'info_contingut.canals[0].id',
    'info_contingut.codi_etic',
    'info_contingut.codi_etic[0].desc',
    'info_contingut.codi_etic[0].id',
    'info_contingut.codis_etics[0].desc',
    'info_contingut.codis_etics[0].id',
    'info_contingut.data_emissio',
    'info_contingut.domini',
    'info_contingut.durada',
    'info_contingut.entradeta_promo',
    'info_contingut.entradeta',
    'info_contingut.formats[0].id',
    'info_contingut.id',
    'info_contingut.isDirecte',
    'info_contingut.logos_canals[0].id',
    'info_contingut.nombonic',
    'info_contingut.programa',
    'info_contingut.programes.programa[0].id',
    'info_contingut.programes.programa[0].tipologia',
    'info_contingut.programes[0].id ',
    'info_contingut.programes[0].id',
    'info_contingut.programes[0].tipologia',
    'info_contingut.slug',
    'info_contingut.tipologia',
    'info_contingut.tipus',
    'info_contingut.titol_promo',
    'info_contingut.titol',
    'info_contingut.permatitle'
];

export const optimizeDataSpecs = [
    {
        key: 'tira-directes',
        pickSpec: {
            items: 'canal',
            itemsPickPaths: directes_itemsPickPaths,
            itemsImatgesPick: [
                {
                    imatgesPath: 'ara_fem.imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_keyframe_directe]
                }
            ]
        }
    },
    {
        key: 'tira-items',
        field: 'POSTER_GRAN',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: tira_items_itemsPickPaths,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_postergran]
                }
            ]
        }
    },
    {
        key: 'tira-items',
        field: 'POSTER_PETIT',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: tira_items_itemsPickPaths,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_posterpetit]
                }
            ]
        }
    },
    {
        key: 'tira-items',
        field: 'QUADRAT',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: tira_items_itemsPickPaths,
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_quadrat]
                }
            ]
        }
    },
    {
        key: 'tira-items',
        field: 'KEYFRAME',
        pickSpec: {
            root: 'resposta',
            rootPickPaths: ['paginacio'],
            items: 'resposta.items.item',
            itemsPickPaths: [...tira_items_itemsPickPaths, ...infocontingut_itemsPickPaths],
            itemsImatgesPick: [
                {
                    imatgesPath: 'imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_keyframe]
                },
                {
                    imatgesPath: 'info_contingut.imatges',
                    pickTipus: [tipusImatgePredef.hbbtv_superdestacat, tipusImatgePredef.hbbtv_keyframe]
                }
            ]
        }
    }
];
