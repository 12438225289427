import { createContext, useState } from "react";

export const RedlContext = createContext({});

const RedlProvider = (props) => {
    const [zoom, setZoom] = useState(100)
    const [showRightSidePanel, setShowRightSidePanel] = useState(null);
    const [showLayout, setShowLayout] = useState(false);

    const value = {
        zoom,
        setZoom,
        buttonZoom: 100 / zoom * 100,
        showRightSidePanel,
        setShowRightSidePanel,
        showLayout,
        setShowLayout,
    }

    return (
        <RedlContext.Provider value={value}>
            {props.children}
        </RedlContext.Provider>
    );
};

export default RedlProvider;