import { useEffect, useRef, useState } from 'react';
import { useModal } from 'hooks/3catinfo/useModal';
import Boto from 'components/3catinfo/Boto';
import ItemVideoReel from './ItemVideoReel';
import __isUndefined from 'lodash/isUndefined';
import __isEmpty from 'lodash/isEmpty';
import styles from '../reels.module.scss';

const getInfoNavegacio = (items, selectedReel) => {
    return {
        numReelSelected: items?.findIndex((reel) => reel.id === parseInt(selectedReel)) + 1,
        numItems: items?.length
    };
};

const Navegacio = (props) => {
    const { items, selectedReel, onNextVideo } = props;
    const [infoNavegacio, setInfoNavegacio] = useState(getInfoNavegacio(items, selectedReel));

    useEffect(() => {
        setInfoNavegacio(getInfoNavegacio(items, selectedReel));
    }, [items, selectedReel]);

    const handleNav = (isPrevious) => {
        onNextVideo(selectedReel, isPrevious);
    };

    return (
        <div className={styles.modalContentDesktopNavegacio}>
            <div className={styles.modalInnerDesktopNavegacio}>
                <div className={styles.modalNavs}>
                    <Boto
                        className={styles.modalNav}
                        icona='fletxa_simple_amunt'
                        isIconaDreta={false}
                        isIconaBlanc={false}
                        altIcona='Reel previ'
                        tag='button'
                        mida={48}
                        onClick={() => handleNav(true)}
                    />
                    <Boto
                        className={styles.modalNav}
                        icona='fletxa_simple_avall'
                        isIconaDreta={false}
                        isIconaBlanc={false}
                        altIcona='Reel següent'
                        tag='button'
                        mida={48}
                        onClick={() => handleNav(false)}
                    />
                </div>
                <div className={styles.modalNavPosicio}>
                    {infoNavegacio.numReelSelected} / {infoNavegacio.numItems}
                </div>
            </div>
        </div>
    );
};

const _getNextReel = (currentId) => {
    const currentDiv = document.getElementById(`reel-${currentId}`);
    const nextElementSibling = currentDiv.nextElementSibling?.getAttribute('id').replace('reel-', '');

    if (__isUndefined(nextElementSibling))
        return currentDiv.parentNode.firstElementChild?.getAttribute('id').replace('reel-', '');

    return nextElementSibling;
};

const _getPrevReel = (currentId) => {
    const currentDiv = document.getElementById(`reel-${currentId}`);
    const prevElementSibling = currentDiv.previousElementSibling?.getAttribute('id').replace('reel-', '');

    if (__isUndefined(prevElementSibling)) return currentDiv.parentNode.lastElementChild?.getAttribute('id').replace('reel-', '');

    return prevElementSibling;
};

const ModalReels = (props) => {
    const { items, idSelected } = props;
    const [selectedReel, setSelectedReel] = useState(idSelected);
    const reelsListRef = useRef({});
    const { centerDivOnModal } = useModal();

    useEffect(() => {
        centerDivOnModal(`reel-video-${selectedReel}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReel]);

    const onNextVideo = (currentId, isPrevious = false) => {
        const nextReelId = isPrevious ? _getPrevReel(currentId) : _getNextReel(currentId);
        reelsListRef.current[selectedReel].stopVideoReel();
        reelsListRef.current[nextReelId].playVideoReel();
        setSelectedReel(nextReelId);
    };

    const setCurrentReel = (nextReelId) => {
        if (nextReelId !== selectedReel) {
            reelsListRef.current[selectedReel].stopVideoReel();
            setSelectedReel(nextReelId);
        }
    };

    const _iterateItems = () => {
        return items.map((item) => (
            <ItemVideoReel
                key={`item-modal-reel-${item.id}`}
                ref={(element) => (reelsListRef.current[item.id] = element)}
                autoPlay={item.id === idSelected}
                parametresExtraWs=''
                versio='vast'
                element={item}
                onNextVideo={onNextVideo}
                setCurrentReel={setCurrentReel}
                selectedReel={selectedReel}
            />
        ));
    };

    return (
        <>
            <div className={styles.contentModalReels}>{_iterateItems()}</div>
            <Navegacio items={items} selectedReel={selectedReel} onNextVideo={onNextVideo} />
        </>
    );
};

ModalReels.defaultProps = {
    items: []
};

export default ModalReels;
