import { useEffect, useState } from 'react';
import { getLayoutModulePropsByName } from '@portal-internet/core';
import { useModal } from 'hooks/3catinfo/useModal';
import { useData } from 'hooks/useData';
import ModalReels from './ModalReels';
import __isEmpty from 'lodash/isEmpty';
import styles from '../reels.module.scss';

const getItemsReelsFromLayout = (props) => {
    const { layout } = props;
    const node = getLayoutModulePropsByName(layout?.structure, 'Reels');
    const items = node?.finalProps?.items;
    return items || [];
};

export const useSharedReel = (modalProps) => {
    const urlDefaultReels =
        '%%dataResources.apiCCMA%%/tot?_format=json&frontal=trescat-trespldocu-novetatdoc&items_pagina=40&pagina=1&sdom=img&version=2.0&cache=180&https=true&master=yes';
    const urlSelectedReel =
        '%%dataResources.apiCCMA%%/videos?_format=json&id=__ID_SELECTED_REEL__&no_agrupacio=PUAG_PROGVID&origen=item&pagina=1&sdom=img&version=2.0&cache=180&https=true&master=yes';

    const { setModal } = useModal();
    const { data: dataDefaultReels, getDades: getDadesDefaultReels } = useData(); // Obtenir els reels per defecte a partir de la crida
    const { data: dataSelectedReel, getDades: getDadesSelectedReel } = useData(); // Obtenir el reel amb l'id compartit en el cas que no el tinguem dins els items
    const [idSelectedReel, setIdSelectedReel] = useState();
    const [data, setData] = useState();

    const getUrlSelectedReel = () => {
        return urlSelectedReel.replace('__ID_SELECTED_REEL__', idSelectedReel);
    };

    useEffect(() => {
        if (dataDefaultReels) setData(dataDefaultReels);
    }, [dataDefaultReels]);

    useEffect(() => {
        if (!__isEmpty(data) && idSelectedReel) {
            const indexSelectedReel = data.findIndex((item) => item.id === idSelectedReel);
            if (indexSelectedReel !== -1) {
                const selectedReel = data.splice(indexSelectedReel, 1);
                data.unshift(selectedReel[0]);
                setModal(<ModalReels items={data} idSelected={idSelectedReel} />, { className: styles.reelModal });
            } else {
                getDadesSelectedReel(getUrlSelectedReel());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, idSelectedReel]);

    useEffect(() => {
        if (!__isEmpty(data) && !__isEmpty(dataSelectedReel) && !__isEmpty(dataSelectedReel.item)) {
            data.unshift(dataSelectedReel.item);
            setModal(<ModalReels items={data} idSelected={idSelectedReel} />, { className: styles.reelModal });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSelectedReel]);

    const getSharedReelModal = (id) => {
        const items = getItemsReelsFromLayout(modalProps);
        if (__isEmpty(items)) {
            getDadesDefaultReels(urlDefaultReels);
        } else {
            setData(items);
        }
        setIdSelectedReel(parseInt(id));
    };

    return {
        getSharedReelModal
    };
};
