export default function VideoBase(props) {
    const { playerVideoTagId } = props;

    return (
        <>
            <div className='videoviewbase' id={'videoviewbase_' + playerVideoTagId}>
                <div className='videowrapper'>
                        <video 
                            poster='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
                            width='100%'
                            height='100%'
                            className='meplayer'
                            id={playerVideoTagId}
                        ></video>
                </div>
                <div className='poster'></div>
            </div>
            {/* <div className='R-dvrmsg fade'>MOU-TE PER LA BARRA PER TIRAR ENRERE</div> */}
            <div className='ratiokeeper'></div>
        </>
    );
}
